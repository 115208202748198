import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Heading,
  Th,
  Td,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Badge,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
// import Navbar from "../../Cenrum-ResearchAnalyst-Head/Components/Navbar";
import Navbar from "../../Components/RasearchAnalyst-Head/Navbar";
import Cookies from "js-cookie";
import axios from "axios";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSingleBasketData,
  makeBasketDecision,
} from "../../Redux/basketReducer/action";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { fetchSymbols } from "../../Redux/symbolReducer/action";

export default function RaHeadBasketDetails() {
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState("");

  const [activeTab, setActiveTab] = useState(0);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [totalFundRequired, setTotalFundRequired] = useState(0);
  const [editableBaskets, setEditableBaskets] = useState({});

  const [researchHeadMessage, setresearchHeadMessage] = useState("");
  const [updateToggle, setUpdateToggle] = useState(false);
  const [statusUpdated, setStatusUpdated] = useState(true);
  const [rejected, setRejected] = useState(false);
  const [isApprovalPending,setIsApprovalPending]=useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  let { id } = useParams();
  let token = Cookies.get("login_token_rh");
 
  const Symbols = useSelector((store) => store.symbolsReducer.symbols);


  useEffect(() => {
    dispatch(fetchSingleBasketData(id, token))
      .then((res) => {
       
  
        // Token expiration check
        if (res.detail === "Token has expired") {
          Cookies.set("login_token_rh", "");
          Cookies.set("username_rh", "");
          navigate("/rahead");
        }
  
        // Permission error handling
        if (res.message === "You do not have permission to access the data") {
          Cookies.set("login_token_rh", "");
          Cookies.set("username_rh", "");
          // navigate('/rahead');
        }
  
        // Basket data
        const basket = res.data.basketList[0]; // Assuming the basket list is returned in the first index
        setData(basket);
  
        let isApproved = false;
        let isRejected = false;
        let isPending = false;
        let isApprovalPending = false; // Updated name
  
        // Check instruments of type "Primary" and raHeadStatus
        basket.instrumentList.forEach((instrument) => {
          if (instrument.instrumentType === "Primary") {
            if (instrument.raHeadStatus === "APPROVED") {
              isApproved = true;
            } else if (instrument.raHeadStatus === "") {
              isPending = true;
              // isApprovalRequired = true; // Set true if any raHeadStatus is empty
            } else if (instrument.raHeadStatus === "REJECTED") {
              isRejected = true;
            }
          }
        });
  
            // Check if any instrument has empty raHeadStatus
      isApprovalPending = basket.instrumentList.some((instrument) => instrument.raHeadStatus === "");

      // Set the approval pending state
      setIsApprovalPending(isApprovalPending);
  
        // Setting the basket status based on the instruments
        if (isRejected) {
          setStatus("REJECTED");
          setRejected(true);
          setStatusUpdated(false);
        } else if (isPending) {
          setStatus("PENDING");
          setRejected(false);
        } else if (isApproved) {
          setStatus("APPROVED");
          setRejected(false);
          setStatusUpdated(false);
        }
      })
      .catch((error) => console.log(error));
  }, [id, token, updateToggle]);
  
  

  useEffect(() => {
    if (data) {
      const total = instruments.reduce(
        (acc, instrument) => acc + calculateFundREquired(instrument),
        0
      );
      setTotalFundRequired(total);
    }
  }, [data]);

  useEffect(() => {
    dispatch(fetchSymbols(token));
  }, []);

  if (!data) {
    return (
      <Box>
        <Navbar />
        <Text>Loading....</Text>
      </Box>
    );
  }

  const itemsPerPage = 10;
  const instruments = Object.values(data.instrumentList);
  const totalPages = Math.ceil(instruments.length / itemsPerPage);

  // const handlePageChange = (newPage) => {
  //   setCurrentPage(newPage);
  // };
 // Pagination helpers
 const handlePageChange = (newPage) => setCurrentPage(newPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
 
  // const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;
  const currentInstruments = instruments.slice(startIndex, endIndex);



 
  const handleReject = () => {
    setShowRejectModal(true);
  };

  const handleCloseRejectModal = () => {
    setShowRejectModal(false);
    setresearchHeadMessage("");
  };

  // Handle Approve Click (New)
  const handleApprove = () => {
    setShowApproveModal(true);
  };

  // Handle Approve Modal Close (New)
  const handleCloseApproveModal = () => {
    setShowApproveModal(false);
    setresearchHeadMessage("");
  };

  const handleConfirmReject = () => {
    if (researchHeadMessage !== "") {
      let decision = "REJECTED";

      dispatch(makeBasketDecision(decision, id, token, researchHeadMessage))
        .then((res) => {
        
          if (res.status === "success") {
            // setStatusUpdated(false)
            toast({
              title: "Basket data has been rejected.",
              position: "bottom",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
            setUpdateToggle(!updateToggle)
          } else {
            toast({
              title: res.message,
              position: "bottom",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
          setUpdateToggle(!updateToggle);
        })
        .catch((error) => {
          toast({
            title: "An error occurred.",
            position: "bottom",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
          console.log(error);
        });
      setShowRejectModal(false);
      setresearchHeadMessage("");
    }
  };

  // Handle Confirm Approve (New)
  const handleConfirmApprove = () => {
    if (researchHeadMessage !== "") {
      let decision = "APPROVED";

      dispatch(makeBasketDecision(decision, id, token, researchHeadMessage))
        .then((res) => {
       
          if (res.status === "success") {
            toast({
              title: "Basket has been approved.",
              position: "bottom",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
            setUpdateToggle(!updateToggle)
          } else {
            toast({
              title: res.message,
              position: "bottom",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
          setUpdateToggle(!updateToggle);
        })
        .catch((error) => {
          toast({
            title: "An error occurred.",
            position: "bottom",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
          console.log(error);
        });
      setShowApproveModal(false);
      setresearchHeadMessage(""); // Reset the message
    }
  };

 
  const calculateQuantile = (instrumentListData) => {
    const qty = instrumentListData.quantity;
    const stopLoss = instrumentListData.stopLoss;
    const takeProfit = instrumentListData.takeProfit;
    const cmp = instrumentListData.currentPrice;
    const quantile = (cmp - stopLoss) / (takeProfit - stopLoss);
    // Convert quantile to percentage
    const quantilePercentage = quantile * 100;

    let Result = Math.floor(quantilePercentage);

    return `${Result}%`;
  };

  const calculateFundREquired = (instrumentListData) => {
    const qty = instrumentListData.quantity;
    const cmp = instrumentListData.currentPrice;
    const fundRequired = Math.floor(cmp * qty);
    return fundRequired;
  };

  const InstrumentRow = ({ instrument }) => {
    const quantileValue = parseFloat(calculateQuantile(instrument));

    let badgeText;
    let badgeColor;

    if (quantileValue > 100) {
      badgeText = "Book Profit";
      badgeColor = "green";
    } else if (quantileValue > 0 && quantileValue <= 100) {
      badgeText = "Intrade";
      badgeColor = "green";
    } else {
      badgeText = "Stop Loss";
      badgeColor = "red";
    }

    return <Badge colorScheme={badgeColor}>{badgeText}</Badge>;
  };

  const handleSymbolName = (symbol) => {
    if (symbol !== "" && symbol !== null) {
      let filterSymbolName = Symbols.filter((ele) => ele.instrument == symbol);
      let result = filterSymbolName[0];

      if (result !== undefined) {
        return result.name;
      }
    }

    return symbol; // Return an empty string if the symbol is not found or invalid
  };

  const handleUpsidePotential = (instrumentListData) => {
    let cmp = Number(instrumentListData.currentPrice);
    let takeProfit = Number(instrumentListData.takeProfit);

    let upsidePotential = ((takeProfit - cmp) / cmp) * 100;

    let upsidePotentialPercentage = Math.floor(upsidePotential);

    if (upsidePotentialPercentage < 0) {
      return `Target Achieved`;
    }
    return `${upsidePotentialPercentage}%`;
  };

  const rationalLines =
    data && data.rationale
      ? data.rationale
          .split("\n")
          .map((line) => line.trim())
          .filter((line) => line.length > 0)
      : []; // Default to an empty array if data.rationale is undefined

  const handleBackClick = () => {
    navigate("/rahead/dashboard");
  };
 
  // Filter symbols based on the search term
  // const filteredSymbols = Symbols.filter((item) =>
  //   item.name.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  // Filters for tables

  const newInstruments = instruments.filter(
    (instrument) =>
      instrument.instrumentType === "Secondary" &&
      instrument.raHeadStatus === ""
  );



  
// Step 1: Create a count of instruments
const instrumentCount = instruments.reduce((acc, instrument) => {
  acc[instrument.instrument] = (acc[instrument.instrument] || 0) + 1;
  return acc;
}, {});

// Step 2: Filter out instruments based on your criteria
const secondaryInstruments = instruments
  .filter((instrument, index, self) => {
    // Ensure uniqueness by checking if it's the first occurrence
    const isFirstOccurrence = index === self.findIndex(i => i.instrument === instrument.instrument);

    // Get all instruments with the same ID
    const sameInstruments = self.filter(i => i.instrument === instrument.instrument);

    // Check for the presence of APPROVED and REJECTED statuses
    const hasApproved = sameInstruments.some(i => i.raHeadStatus === "APPROVED");
    const hasRejected = sameInstruments.some(i => i.raHeadStatus === "REJECTED");

    // Include the instrument if:
    // 1. It is APPROVED and there are no other APPROVED instruments
    // 2. It is the first occurrence of APPROVED when both statuses exist
    return (
      (instrument.raHeadStatus === "APPROVED" && hasApproved && hasRejected && isFirstOccurrence) ||
      (instrument.raHeadStatus === "APPROVED" && !hasRejected && instrumentCount[instrument.instrument] === 1) ||
      (instrument.raHeadStatus === "REJECTED" && !hasApproved && isFirstOccurrence)
    );
  })
  .reverse(); 

let newInstrumentsData   =secondaryInstruments.filter((instrument)=>instrument.raHeadStatus==="APPROVED")

 

  const primaryInstruments = instruments.filter(
    (instrument) => instrument.instrumentType === "Primary"
  );


  const currentNewInstruments = newInstruments.slice(startIndex, endIndex);
  const currentSecondaryInstruments = secondaryInstruments.slice(
    startIndex,
    endIndex
  );
  const currentPrimaryInstruments = primaryInstruments.slice(
    startIndex,
    endIndex
  );

  return (
    <Box>
      <Navbar />

      <Flex align="center" justify="flex-start" mt={2} ml={4}>
        <IconButton
          icon={<ArrowBackIcon boxSize={8} />}
          aria-label="Go back"
          onClick={handleBackClick}
          size="lg"
          isRound
          bgColor="#244c9c"
          color="white"
          _hover={{
            transform: "scale(1.2)",
            boxShadow: "0 8px 15px rgba(0, 0, 0, 0.3)",
            bgColor: "#1a3a6b", // Darker shade for hover
          }}
          transition="all 0.3s ease"
          boxShadow="0 4px 6px rgba(0, 0, 0, 0.2)"
          _active={{
            transform: "scale(0.95)",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          }}
        />
      </Flex>

      <Flex
        direction={{ base: "column", md: "column", lg: "row" }}
        mt="10"
        m={{ base: "2", md: "5" }}
        gap={"10px"}
      >
        <Box
          bg="white"
          w={{ base: "100%", md: "100%", lg: "50%" }}
          p="5"
          borderRadius="md"
          boxShadow="md"
          mb={{ base: "5", md: "0" }}
          overflowX="auto" // Ensure horizontal scrolling on small screens
        >
          <Tabs onChange={(index) => setActiveTab(index)} variant="enclosed">
            <TabList>
              <Tab fontWeight="bold">Compositions</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
              <>
                  {/* Table 1: New Instrument List */}
                  {currentNewInstruments.length > 0 ? (
                    <Box>
                      <Text
                        fontSize="2xl"
                        mb="6"
                        fontWeight="bold"
                        color="#244c9c"
                      >
                        Edit Instrument List
                      </Text>
                      <Table size="md">
                        <Thead>
                          <Tr>
                            <Th>#</Th>
                            <Th>Script Name</Th>
                            <Th>Quantity</Th>
                            <Th>Stop Loss</Th>
                            <Th>CMP</Th>
                            <Th>Take Profit</Th>
                            <Th>Order Type</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {currentNewInstruments.map((instrument, index) => (
                            <Tr
                              key={index}
                              // bg={instrument.orderType === "Entry" ? "green.200" : "red.200"}
                            >
                              <Td>{startIndex + index + 1}</Td>
                              <Td>{handleSymbolName(instrument.instrument)}</Td>
                              <Td>{instrument.quantity}</Td>
                              <Td>{instrument.stopLoss}</Td>
                              <Td>{instrument.currentPrice}</Td>
                              <Td>{instrument.takeProfit}</Td>
                              <Td
                                fontWeight="bold"
                                color={
                                  instrument.orderType === "Entry"
                                    ? "green.200"
                                    : "red.200"
                                }
                              >
                                {instrument.orderType}
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </Box>
                  ) : (
                    ""
                  )}

                  {/* Table 2: Secondary Instrument List */}
                  {newInstrumentsData.length > 0 ? (
                    <Box>
                      <Text
                        fontSize="2xl"
                        mt="10"
                        mb="6"
                        fontWeight="bold"
                        color="#244c9c"
                      >
                       New Basket
                      </Text>
                      <Table size="md">
                        <Thead>
                          <Tr>
                            <Th>#</Th>
                            <Th>Script Name</Th>
                            <Th>Quantity</Th>
                            <Th>Stop Loss</Th>
                            <Th>CMP</Th>
                            <Th>Take Profit</Th>
                            <Th>Order Type</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {newInstrumentsData.map(
                            (instrument, index) => (
                              <Tr
                                key={index}
                                
                              >
                                <Td>{startIndex + index + 1}</Td>
                                <Td>{handleSymbolName(instrument.instrument)}</Td>
                                <Td>{instrument.quantity}</Td>
                                <Td>{instrument.stopLoss}</Td>
                                <Td>{instrument.currentPrice}</Td>
                                <Td>{instrument.takeProfit}</Td>
                                <Td fontWeight="bold" color={
                                  instrument.orderType === "Entry"
                                    ? "green.200"
                                    : "red.200"
                                }>
                                  {instrument.orderType}
                                </Td>
                              </Tr>
                            )
                          )}
                        </Tbody>
                      </Table>
                    </Box>
                  ) : (
                    ""
                  )}


   {/* Table 2: Secondary Instrument List */}
   {instruments.length > 0 ? (
                    <Box>
                      <Text
                        fontSize="2xl"
                        mt="10"
                        mb="6"
                        fontWeight="bold"
                        color="#244c9c"
                      >
                       Basket History
                      </Text>
                      <Table size="md">
                        <Thead>
                          <Tr>
                            <Th>#</Th>
                            <Th>Script Name</Th>
                            <Th>Quantity</Th>
                            <Th>Stop Loss</Th>
                            <Th>CMP</Th>
                            <Th>Take Profit</Th>
                            <Th>Order Type</Th>
                            <Th>RaHead Approveal</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {instruments.reverse().map(
                            (instrument, index) => (
                              <Tr
                                key={index}
                                
                              >
                                <Td>{startIndex + index + 1}</Td>
                                <Td>{handleSymbolName(instrument.instrument)}</Td>
                                <Td>{instrument.quantity}</Td>
                                <Td>{instrument.stopLoss}</Td>
                                <Td>{instrument.currentPrice}</Td>
                                <Td>{instrument.takeProfit}</Td>
                                <Td fontWeight="bold" color={
                                  instrument.orderType === "Entry"
                                    ? "green.200"
                                    : "red.200"
                                }>
                                  {instrument.orderType}
                                </Td>

                                <Td fontWeight="bold" color={
                                  instrument.raHeadStatus === "APPROVED"
                                    ? "green.200"
                                    : "red.200"
                                }>
                                  {instrument.raHeadStatus}
                                </Td>
                              </Tr>
                            )
                          )}
                        </Tbody>
                      </Table>
                    </Box>
                  ) : (
                    ""
                  )}
                  {/* Table 3: Primary Instrument List */}

                  <Box>
                    {currentSecondaryInstruments.length > 0 ||
                    currentNewInstruments.length > 0 ? (
                      <Text
                        fontSize="2xl"
                        mt="10"
                        mb="6"
                        fontWeight="bold"
                        color="#244c9c"
                      >
                        Primary Instrument List
                      </Text>
                    ) : (
                      ""
                    )}

                    <Table size="md">
                      <Thead>
                        <Tr>
                          <Th>#</Th>
                          <Th>Script Name</Th>
                          <Th>Quantity</Th>
                          <Th>Stop Loss</Th>
                          <Th>CMP</Th>
                          <Th>Take Profit</Th>
                          <Th>Order Type</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {currentPrimaryInstruments.map((instrument, index) => (
                          <Tr
                            key={index}
                           
                          >
                            <Td>{startIndex + index + 1}</Td>
                            <Td>{handleSymbolName(instrument.instrument)}</Td>
                            <Td>{instrument.quantity}</Td>
                            <Td>{instrument.stopLoss}</Td>
                            <Td>{instrument.currentPrice}</Td>
                            <Td>{instrument.takeProfit}</Td>
                            <Td fontWeight="bold"  color={
                              instrument.orderType === "Entry"
                                ? "green.200"
                                : "red.200"
                            }>{instrument.orderType}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                  {/* Pagination Controls */}
                  {totalPages > 1 && (
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      mt="6"
                    >
                      <Button
                        onClick={() => handlePageChange(currentPage - 1)}
                        size="sm"
                        colorScheme="blue"
                        leftIcon={<FaArrowLeft />}
                        isDisabled={currentPage === 1}
                        bg={currentPage === 1 ? "gray.300" : "blue.500"}
                        _hover={{
                          bg: currentPage === 1 ? "gray.300" : "blue.400",
                        }}
                      >
                        Previous
                      </Button>

                      <Text fontWeight="bold" color="#244c9c">
                        Page {currentPage} of {totalPages}
                      </Text>

                      <Button
                        onClick={() => handlePageChange(currentPage + 1)}
                        size="sm"
                        colorScheme="blue"
                        rightIcon={<FaArrowRight />}
                        isDisabled={currentPage === totalPages}
                        bg={
                          currentPage === totalPages ? "gray.300" : "blue.500"
                        }
                        _hover={{
                          bg:
                            currentPage === totalPages
                              ? "gray.300"
                              : "blue.400",
                        }}
                      >
                        Next
                      </Button>
                    </Flex>
                  )}
                </>

                {isApprovalPending ? (
                  <Box
                    display={"flex"}
                    justifyContent={"space-evenly"}
                    marginTop={"5"}
                  >
                    {}
                    <Button colorScheme="green" onClick={handleApprove}>
                      Approve
                    </Button>
                    <Button colorScheme="red" onClick={handleReject}>
                      Reject
                    </Button>
                  </Box>
                ) : (
                  ""
                )}
              </TabPanel>
              <TabPanel>
                <Text>Client data will be displayed here.</Text>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
        <Box
          bg="white"
          w={{ base: "100%", md: "100%", lg: "50%" }}
          p="5"
          borderRadius="md"
          boxShadow="md"
          mt={{ base: "5", md: "0" }}
        >
          <Text fontSize="xl" fontWeight="bold" mb="4" color="blue.500">
            Basket Info: {data.title}
          </Text>
          <Table variant="simple" size="sm">
            <Tbody>
              <Tr>
                <Td>
                  <strong>Basket Name:</strong>
                </Td>
                <Td>{data.title}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Basket Description:</strong>
                </Td>
                <Td fontFamily={"helvetica"}>{data.description}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Basket Rational:</strong>
                </Td>
                <Td>
                  {rationalLines.map((line, index) => (
                    <Box key={index} display="flex" mb={4}>
                      {/* <Text as="span" mr={2} fontSize="md" color="gray.700"> */}
                      <Heading as="h5" size="sm" mr={2}>
                        •
                      </Heading>
                      {/* </Text> */}
                      <Text fontSize="sm" color="gray.700">
                        {line}
                      </Text>
                    </Box>
                  ))}
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Expiry Date:</strong>
                </Td>
                <Td>{data.expiryDate}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Creation Date:</strong>
                </Td>
                <Td>{data.creationDate}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong> Created By:</strong>
                </Td>
                <Td fontFamily={"helvetica"}>{data.createdBy}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Exchange Type:</strong>
                </Td>
                <Td fontFamily={"helvetica"}>{data.exchange}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Minimum Fund Required:</strong>
                </Td>
                <Td fontFamily={"helvetica"}> {totalFundRequired}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Research Head Approval:</strong>
                </Td>
                <Td>
                  {status === "PENDING" && (
                    <Text
                      bg="gray.200"
                      p="1"
                      px="3"
                      width={{ base: "90%", md: "40%", lg: "50%" }}
                      textAlign={"center"}
                      borderRadius="md"
                    >
                      Pending
                    </Text>
                  )}
                  {status === "APPROVED" && (
                    <Text
                      bg="green.200"
                      p="1"
                      px="3"
                      width={{ base: "50%", md: "30%", lg: "50%" }}
                      textAlign={"center"}
                      borderRadius="md"
                    >
                      Yes
                    </Text>
                  )}
                  {status === "REJECTED" && (
                    <Text
                      bg="red.200"
                      p="1"
                      px="3"
                      width={{ base: "50%", md: "30%", lg: "30%" }}
                      textAlign={"center"}
                      borderRadius="md"
                    >
                      No
                    </Text>
                  )}
                </Td>
              </Tr>

              {rejected ? (
                <Tr>
                  <Td>
                    <strong>Rejection Reason:</strong>
                  </Td>
                  <Td>{data.rejectedReason}</Td>
                </Tr>
              ) : (
                ""
              )}
            </Tbody>
          </Table>
        </Box>
      </Flex>

      {/* Rejection Reason Modal */}
      <Modal isOpen={showRejectModal} onClose={handleCloseRejectModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Provide Reason for Rejection</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              placeholder="Enter reason for rejection..."
              rows={4}
              value={researchHeadMessage}
              onChange={(e) => setresearchHeadMessage(e.target.value)}
              width="100%"
              resize="none"
              border="1px solid #5274ac"
              _focus={{
                borderColor: "#5274ac",
                boxShadow: "0 0 0 1px #5274ac",
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleCloseRejectModal}>
              Close
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleConfirmReject}
              disabled={!researchHeadMessage}
            >
              Confirm Reject
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* // Approval Reason Modal (New Modal for Approval) */}
      <Modal isOpen={showApproveModal} onClose={handleCloseApproveModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Provide Reason for Approval</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              placeholder="Enter reason for approval..."
              rows={4}
              value={researchHeadMessage}
              onChange={(e) => setresearchHeadMessage(e.target.value)}
              width="100%"
              resize="none"
              border="1px solid #5274ac"
              _focus={{
                borderColor: "#5274ac",
                boxShadow: "0 0 0 1px #5274ac",
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleCloseApproveModal}>
              Close
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleConfirmApprove}
              disabled={!researchHeadMessage}
            >
              Confirm Approve
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
